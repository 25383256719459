body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: calc(100vh - 80px);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.p,
a {
	color: #1b1741;
}

h1,
.h1 {
	@apply text-4xl lg:text-5xl;
}

h2,
.h2 {
	@apply text-2xl lg:text-3xl;
}

h3,
.h3 {
	@apply text-xl lg:text-2xl;
}

h4,
.h4 {
	@apply text-lg lg:text-xl;
}

p,
.p {
	@apply text-base;
}

.recharts-surface {
	overflow: visible;
}

.recharts-tooltip-wrapper {
	@apply outline-none;
}
